import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const SectionFixedHeight = styled(Section)(
    () =>
        css`
            height: 548px;

            ${mediaQueries.md} {
                padding: 52px 60px;
            }

            ${mediaQueries.sm} {
                padding: 44px 32px;
            }
        `,
);

export const ClientInfo = styled(Common.Div)(
    () =>
        css`
            display: flex;
            gap: 4px;
            margin-top: 32px;

            ${mediaQueries.md} {
                flex-direction: column;
            }
        `,
);

export const ProjectBg = styled(Common.Div)<{ bgSrc: string }>(
    ({ bgSrc }) =>
        css`
            position: absolute;
            left: 0;
            background-image: url(${bgSrc});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-top: 200px;
            width: 100%;
            height: 306px;

            ${mediaQueries.md} {
                margin-top: 260px;
            }

            ${mediaQueries.sm} {
                margin-top: 240px;
            }

            ${mediaQueries.xs} {
                margin-top: 292px;
            }
        `,
);

export const WhiteSeparator = styled(Common.Div)`
    border: 1px solid white;
    width: 1px;
    height: 313px;
    position: absolute;
    top: -52px;
`;

export const MaxWidthText = styled(Common.Div)`
    max-width: 780px;
`;

export const SectionLessPadding = styled(Section)`
    padding: 32px 156px;

    ${mediaQueries.md} {
        padding: 52px 60px;
    }

    ${mediaQueries.sm} {
        padding: 44px 32px;
    }
`;

export const ListItem = styled.li(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.text};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.regular};
            line-height: 28px;
            width: 414px;

            &::marker {
                color: ${colors.text};
                font-size: ${fontSizes.f16};
            }
            ${mediaQueries.ipadH} {
                width: 100%;
            }
        `,
);

export const ListPaddings = styled.ul(
    () =>
        css`
            ${mediaQueries.md} {
                padding-left: 16px;
            }
        `,
);
