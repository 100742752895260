import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import { ArrowButton } from 'components/_universal/ArrowButton';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { CaseStudyItem } from 'sections/CaseStudies/CaseStudyItem';
import {
    ClientInfo,
    ListItem,
    ListPaddings,
    MaxWidthText,
    ProjectBg,
    SectionFixedHeight,
    SectionLessPadding,
    WhiteSeparator,
} from 'sections/OurProjects/ProjectSection.styled';
import { ProjectSlidesSwiper } from 'sections/OurProjects/ProjectSlidesSwiper';
import { CASE_STUDIES } from 'shared/data/CaseStudies.data';
import { PROJECT_CONTENTS, TProjectName } from 'shared/data/Project.data';
import { ROUTE_CASE_STUDIES } from 'shared/paths';
import shortid from 'shortid';
import { B0, B2, B3, H2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

const pickCaseStudies = (projectName: TProjectName) => {
    const activeProjectIndex = CASE_STUDIES.findIndex(
        (cs) => cs.projectName === projectName,
    );
    if (activeProjectIndex === 0) {
        return CASE_STUDIES.slice(1, 4);
    }
    if (activeProjectIndex === CASE_STUDIES.length - 1) {
        return CASE_STUDIES.slice(0, 3);
    }
    const missingIndex =
        activeProjectIndex + 2 === CASE_STUDIES.length ? -2 : +2;

    return [
        CASE_STUDIES[activeProjectIndex - 1],
        CASE_STUDIES[activeProjectIndex + 1],
        CASE_STUDIES[activeProjectIndex + missingIndex],
    ];
};

// NOTE: This content is hydrated via gatsby-node.js (by passing project name)
export default ({
    pageContext,
}: {
    pageContext: {
        projectName: TProjectName;
    };
}) => {
    const { projectName } = pageContext;
    const {
        title,
        client,
        industry,
        region,
        background,
        overview,
        approach,
        highlights,
        deliverables,
        carouselItems,
        hardware,
        description,
        keywords,
    } = PROJECT_CONTENTS[projectName];
    const isMobile = useBreakpoint('md');
    const isSm = useBreakpoint('sm');

    return (
        <CommonMargin>
            <SEO
                title={title}
                description={description}
                meta={[{ name: 'keywords', content: keywords }]}
            />
            <SectionFixedHeight
                variant="secondary"
                withBreadCrumbs
                title={title}
                isFirst
            >
                <ClientInfo>
                    <Common.Div flex="row" gap="8px">
                        <B3 bold>client: </B3>
                        <B3 mr={2}>{client} </B3>
                    </Common.Div>
                    <Common.Div flex="row" gap="8px">
                        <B3 bold>industry: </B3>
                        <B3 mr={2}>{industry} </B3>
                    </Common.Div>
                    <Common.Div flex="row" gap="8px">
                        <B3 bold>region: </B3>
                        <B3>{region} </B3>
                    </Common.Div>
                </ClientInfo>
                <ProjectBg bgSrc={background} />
            </SectionFixedHeight>
            <SectionLessPadding>
                <WhiteSeparator />
                <Common.Div pl={4} mb={6} minH="200px">
                    <B3 mb={2} bold>
                        overview
                    </B3>
                    <MaxWidthText>
                        <B3>{overview}</B3>
                    </MaxWidthText>
                </Common.Div>
                <MaxWidthText mb={8}>
                    <B0 mb={4} secondary>
                        Approach and Solution
                    </B0>
                    <B2 mb={8}>{approach}</B2>
                    <Common.Div flex={isMobile ? 'column' : 'row'} gap="48px">
                        <Common.Div>
                            <B0 mb={4} secondary>
                                Project highlights
                            </B0>
                            <ListPaddings>
                                {highlights.map((item) => (
                                    <ListItem key={shortid.generate()}>
                                        {item}
                                    </ListItem>
                                ))}
                            </ListPaddings>
                        </Common.Div>
                        <Common.Div>
                            <B0 mb={4} secondary>
                                Deliverables
                            </B0>
                            <ListPaddings>
                                {deliverables.map((item) => (
                                    <ListItem key={shortid.generate()}>
                                        {item}
                                    </ListItem>
                                ))}
                            </ListPaddings>
                        </Common.Div>
                    </Common.Div>
                    {hardware?.length ? (
                        <Common.Div mt={4}>
                            <B0 mb={4} secondary>
                                Hardware
                            </B0>
                            <ListPaddings>
                                {hardware.map((item) => (
                                    <ListItem key={shortid.generate()}>
                                        {item}
                                    </ListItem>
                                ))}
                            </ListPaddings>
                        </Common.Div>
                    ) : (
                        <></>
                    )}
                </MaxWidthText>
                <ProjectSlidesSwiper items={carouselItems} />

                <H2 mb={6} ml={isMobile ? 0 : 6}>
                    more case studies
                </H2>
                <Common.Div
                    flex={'row'}
                    gap="30px"
                    mb={8}
                    flexWrap="wrap"
                    alignItems={isSm ? 'center' : 'flex-start'}
                    ml={isMobile ? 0 : 6}
                >
                    {pickCaseStudies(projectName).map(
                        ({ src, desc, link, title }) => (
                            <CaseStudyItem
                                bgSrc={src}
                                desc={desc}
                                link={link}
                                title={title}
                            />
                        ),
                    )}
                </Common.Div>
                <Common.Div ml={isMobile ? 0 : 6}>
                    <ArrowButton
                        onClick={() => navigate(ROUTE_CASE_STUDIES)}
                        text="read more"
                    />
                </Common.Div>
            </SectionLessPadding>
            <TakeThisStepSection />
            <TrustedBySection />
        </CommonMargin>
    );
};
