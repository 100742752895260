import ArrowLeft from 'assets/images/_infoGraphics/arrowCarouselLeft.svg';
import ArrowRight from 'assets/images/_infoGraphics/arrowCarouselRight.svg';
import * as Common from 'components/_universal/Common';
import React, { useCallback, useState } from 'react';
import { SwiperDotsFiller } from 'sections/Home/TestimonialsSection.styled';
import { mediaQueries } from 'shared/breakpoints';
import shortId from 'shortid';
import styled, { css } from 'styled-components';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

// NOTE: Must be synchronized with breakpoints in swiper
export const SwiperWrapper = styled(Common.Div)(
    () =>
        css`
            width: calc(100% - 100px);
            .swiper-slide {
                justify-content: center;
                display: flex;
            }

            ${mediaQueries.xs} {
                width: 100%;
                .swiper-pagination-bullet {
                    width: 5px;
                    height: 5px;
                }

                .swiper-pagination-bullet-active {
                    background: white;
                    width: 7px;
                    height: 7px;
                }
            }
        `,
);

export const SlideImage = styled(Common.Svg)(
    () => css`
        width: 100%;
    `,
);

export const SwiperLeftArrowBack = styled(Common.Div)(
    () =>
        css`
            width: 20px;
            background-image: url(${ArrowLeft});
            background-repeat: no-repeat;
            background-position: center;
        `,
);

export const SwiperRightArrowNext = styled(Common.Div)(
    () =>
        css`
            width: 20px;
            background-image: url(${ArrowRight});
            background-repeat: no-repeat;
            background-position: center;
        `,
);

export const ProjectSlidesSwiper = ({
    items = [],
}: {
    items: { src: string }[];
}) => {
    const isXs = useBreakpoint('xs');

    const [swiperRef, setSwiperRef] = useState<SwiperClass>();

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    // NOTE: Swiper breakpoints must be synchronized with breakpoints in styled
    return (
        <Common.Div
            flex="row"
            gap="32px"
            position="relative"
            mb={4}
            justifyContent="space-between"
        >
            {!isXs && <SwiperLeftArrowBack onClick={handlePrevious} />}
            <SwiperWrapper>
                <Swiper
                    onSwiper={setSwiperRef}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    className={'swiper'}
                    loop
                    modules={[Pagination, Navigation, Autoplay]}
                    pagination={isXs && { clickable: true }}
                    slidesPerView={1}
                >
                    {items.map(({ src }) => (
                        <SwiperSlide key={shortId.generate()}>
                            <Common.Div flex="column">
                                <Zoom>
                                    <SlideImage src={src} />
                                </Zoom>
                                <SwiperDotsFiller />
                            </Common.Div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperWrapper>

            {!isXs && <SwiperRightArrowNext onClick={handleNext} />}
        </Common.Div>
    );
};
